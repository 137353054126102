import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, merge, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const PLATFORM_NAME = 'IXFI';
const DEFAULT_TITLE = 'Rewards | ' + PLATFORM_NAME;

@Injectable({
    providedIn: 'root'
})
export class PageTitleService implements OnDestroy {
    title$ = new Subject<string>(); //initial value for the title

    private titleRoute$: Observable<string | undefined> = //emit any title value defined in the current route
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.getTitle(this.activatedRoute.firstChild))
        );

    private titleState$ = merge(this.title$, this.titleRoute$).pipe(
        //listen to either title$ or titleRoute$ values
        filter(title => {
            const is_Undefined = title !== undefined;
            if (!is_Undefined) this.titleService.setTitle(`${DEFAULT_TITLE}`);
            return is_Undefined;
        }),
        tap(title => this.titleService.setTitle(`${title} | ${DEFAULT_TITLE}`))
    );

    setTitle(pageTitle: string) {
        this.titleService.setTitle(`${pageTitle} | ${DEFAULT_TITLE}`);
    }

    constructor(
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.titleState$.subscribe();
    }

    private getTitle(activatedRoute: ActivatedRoute | null): string | undefined {
        while (activatedRoute) {
            if (activatedRoute.firstChild) {
                activatedRoute = activatedRoute.firstChild;
            } else if (activatedRoute.snapshot?.data['title']) {
                this.updateMeta(activatedRoute.snapshot.data['meta']);
                if (this.router.routerState.snapshot.url == '/') {
                    return undefined;
                }

                return activatedRoute.snapshot.data['title'] as string;
            } else {
                return undefined;
            }
        }
        return undefined;
    }

    updateMeta(tags: { 'og:description': string; 'og:image': string; canonical: string; keywords?: string }) {
        if (tags && Object.keys(tags).length) {
            for (const tag in tags) {
                if (tag == 'og:description') {
                    this.meta.updateTag({ name: 'description', content: tags[tag] });
                }
                this.meta.updateTag({ name: tag, content: tags[tag] });
            }
        }
    }

    addSchemaMarkup(schemaJson: string, renderer2): void {
        if (environment.production) {
            const existingSchema = this._document.querySelector('script[type="application/ld+json"]');
            if (existingSchema) {
                existingSchema.remove();
            }
            let script = renderer2.createElement('script');
            script.type = `application/ld+json`;
            script.text = schemaJson;
            renderer2.appendChild(this._document.body, script);
        }
    }

    ngOnDestroy(): void {
        this.title$.unsubscribe();
    }
}
